import React, { useContext, useState } from "react";
import "./login.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext.js";
import { IoCloseCircleOutline } from "react-icons/io5";


const Com_Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error,setError] = useState("")

  const { user, loading, dispatch } = useContext(AuthContext);

   const devApiLink = "http://localhost:4348/api/v1";
    const apiLink = "https://xl.notionspromos.com/api/v1";

  const handleLogin = async (ev) => {
    ev.preventDefault();
    dispatch({ type: "LOGIN_START" });

    try {
      const response = await axios.post(`${apiLink}/login`, {
        email,
        password,
      });
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
      // navigate("/puzzle");
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err });
      setError("Invalid email or password",err);
    }
  };

  return (
    <>
      <form className="_form" onSubmit={handleLogin}>
        <input
          required
          type="email"
          placeholder="your@email.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          required
          type="password"
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" disabled={loading}>
          {loading ? "Logging in..." : "Login"}
        </button>
        {error && <div className="_error">{error}</div>}
      </form>
    </>
  );
};

export default Com_Login;
