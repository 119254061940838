import React from 'react'
import './Sucsess.scss'



const Sucsess = () => {

  return (
    <div className="__sucsess">
      <div className="__suc_con">
        <div className="__scu_patry">
          <img src={require("../../img/party.gif")} />
        </div>
        <span>Thank you </span>
        <h1> For your participation</h1>
      </div>
      <div className="__social">
        <p>
          To find out if you are one of the lucky winners, follow us on our
          <br /> social networking pages
        </p>
      </div>
      <div className="social_container">
        <div></div>
        <div>
          <img src={require("../../img/facebook.webp")} />
        </div>
        <div>
          <img src={require("../../img/instagram.webp")} />
        </div>
        <div></div>
      </div>
      <div className="explo">
        <div className="ex_brand_head">
          <h1>Discover our brands</h1>
        </div>
        <div className="brand_logo">
          <div className="itms">
            <a href="https://www.chocodate.com/">
              <img src={require("../../img/chocodate.webp")} />
            </a>
          </div>
          <div className="itms">
            <a href="https://arabiandelights.ae/">
              <img src={require("../../img/ad.png")} />
            </a>
          </div>
          <div className="itms">
            <a href="https://apronsnlove.com/">
              <img src={require("../../img/ap.png")} />
            </a>
          </div>
          <div className="itms">
            <a href="https://www.notionsgroup.com/snacks_brand">
              <img src={require("../../img/xl.webp")} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sucsess
