import React, { useContext, useEffect, useState } from "react";
import "./xlRank.scss";
import { FaStar } from "react-icons/fa6";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext.js";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/navBar/NavBar.jsx";


const XlRank = () => {
  const [data, setData] = useState([]);
  const { user, dispatch } = useContext(AuthContext);
  const navigate = useNavigate()

  const devApiLink = "http://localhost:4348/api/v1";
  const apiLink = "https://xl.notionspromos.com/api/v1";

  const buyersName = user ? user.details.name : "";

  const buyerCode = user ? user.details.code : "";

  // const buyersName = "SFI-C02052";

  const logoutUser = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      await axios.get(`${apiLink}/logout`);
      localStorage.removeItem("user");
      dispatch({ type: "LOGOUT" });
      navigate("/xl");
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching data:", error);
      }
    }
    return () => controller.abort();
  };

  const getBuyersData = () => {
    const getCustomerdata = data.filter(
      (item) => item.Customer_No === buyerCode
    );
    return getCustomerdata[0]; // Return the first item in the filtered array
  };

  const buyerData = getBuyersData();

  // Find the index of the current user
  const buyerIndex = data.findIndex((item) => item.Customer_No === buyerCode);

  // Ensure at least the first three customers are visible
  const startIndex = Math.max(0, buyerIndex - 2);
  const endIndex = Math.min(data.length, buyerIndex + 3);

  // Display the first three customers if they are not already included
  const firstThree = data.slice(0, 3);
  const surroundingData = data.slice(startIndex, endIndex);

  // Combine the arrays while removing duplicates
  // const combinedData = Array.from(new Set([...firstThree, ...surroundingData]));
    const combinedData = Array.from(
      new Set([ ...surroundingData])
    );

  useEffect(() => {
    if (!user) {
      return;
    }
    axios
      .post(`${apiLink}/proxy/get-data`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user]);

  return (
    <div className="__max_w">
      <div className="__min_w">
        <div className="xl_wrp">
          <div className="xl_nav">
            <div className="xl_sec_left">
              <img src={require("../../img/logo_xl_rank.jpg")} alt="" />
            </div>
            <div className="xl_sec_right">
              <div className="xl_sec_in">
                <span>
                  Welcome <br /> {buyerData?.Customer_No}
                </span>
              </div>
              <div className="xl_sec_in">
                <div className="pro_imgs">
                  <img src={require("../../img/proImg.jpg")} alt="" />
                </div>
              </div>
              <div className="xl_sec_in">
                <span>Your Rank {buyerData?.index}</span>
                <div>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
              </div>
              <div className="xl_sec_in">
                <span onClick={logoutUser}>Logout</span>
              </div>
            </div>
          </div>
          <NavBar />
          <div className="xl_wall">
            <img src={require("../../img/wall.jpg")} alt="" />
          </div>
          <div className="rank">
            <div className="rank_img">
              <div className="rank_img_se">
                <img src={require("../../img/proImg.jpg")} alt="" />
              </div>
              <div className="rank_img_info">
                <h2>
                  Welcome, <br /> <em>{buyerData?.Customer_Name}</em>{" "}
                </h2>
                <p>
                  {" "}
                  We hope you are enjoing Xl <br /> Climb the Ranks
                </p>
              </div>
            </div>
            <div className="rank_info">
              <div className="rank_info_img">
                <img src={require("../../img/symb.png")} alt="" />
              </div>
              <div className="rank_info_star">
                <h2>YOUR RANK</h2>
                <div className="stars">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
              </div>
              <div className="rank_info_count">
                <h1>{buyerData?.index}</h1>
              </div>
            </div>
          </div>
          <div className="table">
            <table>
              <tr>
                <th>Customer No</th>
                <th>Buyers</th>
                <th>Value</th>
                <th>Rank</th>
                <th>Gifts</th>
                <th>Place</th>
              </tr>
              <tbody>
                {combinedData.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor:
                        item.Customer_No === buyerCode
                          ? " #7dc87d"
                          : "transparent",
                      color:
                        item.Customer_No === buyerCode ? "white" : "inherit",
                    }}
                  >
                    <td>{item.Customer_No}</td>
                    <td>
                      {item.Customer_No === buyerCode
                        ? item.Customer_Name
                        : "*****"}
                    </td>
                    <td>{item.Line_Amount}</td>
                    <td>{item.index}</td>
                    <td>
                      {item.index === 0
                        ? "CAR"
                        : item.index === 1
                        ? "AIR TICKET"
                        : item.index === 2
                        ? "LAPTOP"
                        : item.index === 3
                        ? "SMART WATCH"
                        : ""}
                    </td>
                    <td>
                      {item.index === 0
                        ? "1st"
                        : item.index === 1
                        ? "2nd"
                        : item.index === 2
                        ? "3rd"
                        : item.index === 3
                        ? "4th"
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default XlRank;
