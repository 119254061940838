import React from 'react'
import Com_Register from '../../components/login/Com_Register';
import { Link } from 'react-router-dom';
import { IoCloseCircleOutline } from "react-icons/io5";



const Register = () => {
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="_login">
          <div className="_log_in">
            <div className="_in_h">
              <h2>Register</h2>
            </div>
            <Com_Register />
            <div className="_rg_pg">
              <small>
                Already a member?{" "}
                <Link to={"/login"} style={{ color: "white" }}>
                  {" "}
                  Login
                </Link>{" "}
              </small>
            </div>
            <Link to={"/"}>
              <div className="close">
                <p>
                  <IoCloseCircleOutline />
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register
