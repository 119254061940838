import React, { useContext } from 'react'
import Com_Login from '../../components/login/Com_Login';
import { Link } from 'react-router-dom';
import { IoCloseCircleOutline } from "react-icons/io5";
import './login.scss'
import { AuthContext } from '../../context/AuthContext';


const Login = () => {


  return (
    <div className="__fw">
      <div className="__mw">
        <div className="_login">
          <div className="_log_in">
            <div className="_in_h">
              <h2>Welcome Back</h2>
              <small>Login to continue</small>
            </div>
            <Com_Login />
            <div className="_rg_pg">
              <small>
                Don't have an account yet?{" "}
                <Link to={"/register"} style={{ color: "white" }}>
                  {" "}
                  Register now
                </Link>
              </small>
            </div>
            <Link to={"/"}>
              <div className="close">
                <p>
                  <IoCloseCircleOutline />
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login
