import React, { useContext, useState } from 'react'
import './xl.scss'
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import Com_Login from '../../components/login/Com_Login';
import Com_Register from '../../components/login/Com_Register';
import Rank_Register from '../../components/login/Rank_Register';

const Xl = () => {

     const { user, dispatch } = useContext(AuthContext);
     const navigate = useLocation()
       const [showBtn, setShowBtn] = useState(false);

     const handleShowBtn = () => {
       setShowBtn(!showBtn);
     };

      const devApiLink = "http://localhost:4348/api/v1";
      const apiLink = "https://xl.notionspromos.com/api/v1";


      const logoutUser = async () => {
        const controller = new AbortController();
        const signal = controller.signal;
        try {
          await axios.get(`${apiLink}/logout`);
          localStorage.removeItem("user");
          dispatch({ type: "LOGOUT" });
          navigate("/login");
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log("Request canceled", error.message);
          } else {
            console.error("Error fetching data:", error);
          }
        }
        return () => controller.abort();
      };



  return (
    <div className="xl" style={{ backgroundColor: "black" }}>
      <div className="xl_heading">
        <div className="xl_head">
          <Link to={"/"}>
            <img src={require("../../img/xl.webp")} alt="" />
          </Link>
          {!user ? (
            <>
              <p>
                Before participating in any XL games, you need to register as a
                user first. <br /> If you already have an account, please log
                in.
              </p>
            </>
          ) : (
            <>
              <h2 style={{ textAlign: "center" }}>
                {" "}
                Welcome <br /> <em>{user?.details?.name}</em>
              </h2>
              <p>Scan your QR code to enter the game</p>
            </>
          )}
        </div>
        {!user ? (
          <div className="xl_login">
            <div className="xl_login_hed">
              {!showBtn ? <h3>Login</h3> : <h3>Register</h3>}
            </div>

            {/* <small> If you have account already login</small> */}
            {!showBtn ? <Com_Login /> : <Rank_Register />}
            {/* <Com_Login /> */}

            {!showBtn && (
              <small>
                {" "}
                If you dont have account{" "}
                <em onClick={handleShowBtn}>register</em>{" "}
              </small>
            )}
            {showBtn && (
              <small>
                {" "}
                If you have account <em onClick={handleShowBtn}>Login</em>{" "}
              </small>
            )}
          </div>
        ) : (
          <>
            <div className="find_rnk">
              <Link to={"/xl-ranks"}>
                <p> Find Your Rank</p>
              </Link>
            </div>
            <div className="xl_login">
              <button onClick={logoutUser}>LOGOUT</button>
            </div>
          </>
        )}
      </div>
      {/* <div className="promo_list">
        <div className="promo_card">
          <h1>Joker</h1>
        </div>
        <div className="promo_card">
          <h1>Game 02</h1>
        </div>
        <div className="promo_card">
          <h1>Game 03</h1>
        </div>
      </div> */}
    </div>
  );
}

export default Xl
