import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "./puzzle.scss";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext.js";

const Puzzle = () => {
  let { qr } = useParams();
  const [greeting, setGreeting] = useState("");
  const [msg, setMsg] = useState("");
  const { user, dispatch } = useContext(AuthContext);
  const userName = user?.details.name;
  const email = user?.details.email;
  const [product, setProduct] = useState([]);
  const [qrProcessed, setQrProcessed] = useState(false);

  const navigate = useNavigate()

  const colors = product;

  useEffect(() => {
    if (!user) {
      return;
    } else {
      getAllProduct();
    }
  }, [user, msg]);

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour < 18) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }
  }, []);

  // const colors = [
  //   "XL_French_Cheese",
  //   "XL_Red_Chili_Pepper",
  //   "XL_Tomato_Ketchup",
  //   "XL_Crystal_Salt",
  //   "XL_Salt_&_Vinegar",
  //   "XL_Flamin_Hot",
  //   "XL_XT_Creamy_Cheddar",
  //   "XL_XT_Flamin_Hot",
  //   "XL_XT_Thai_Sweet_Chilli",
  //   "XL_ST_Tomato",
  //   "XL_ST_Original",
  //   "XL_ST_Thai_Campfire",
  //   "XL_ST_Mild_Spicy",
  //   "XL_ST_Sea_Salt",
  //   "XL_TW_Four_Cheese",
  //   "XL_TW_Pepper_Jack",
  //   "XL_TW_Ketchup",
  //   "XL_TWR_Honey_BBQ",
  //   "XL_TWR_Nacho_Cheese",
  //   "XL_TWR_Himalayan_Salt",
  //   "XL_SP_Firey_Cheese",
  //   "XL_SP_Spanish_Tomato_Basil",
  //   "XL_SP_Salad",
  //   "XL_SP_Smoked_Chili",
  // ];

  const totalItems = 24;
  const percentage = Math.round((colors.length / totalItems) * 100);

  const addQR = async () => {
    const userEmail = user.details?.email;
    if (!userEmail) {
      console.log("User email is not available");
      return;
    }
    if (!qr) {
      console.log("Product not available");
      return;
    }

    try {
      const response = await axios.put(`/add_qr/${qr}`, { email: userEmail });
      setMsg("Perfect, try Next Product");
      setQrProcessed(true);
    } catch (error) {
      console.log(
        "Error adding QR:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const getAllProduct = async () => {
    try {
      await axios.get(`/get_products/${email}`).then((response) => {
        setProduct(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (qr && !qrProcessed) {
      addQR();
    }
  }, [qr, qrProcessed]);

  const logoutUser = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      await axios.get("/logout");
      localStorage.removeItem("user");
      dispatch({ type: "LOGOUT" });
      navigate("/login");
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching data:", error);
      }
    }
    return () => controller.abort();
  };

  return (
    <div className="__max_w" style={{ backgroundColor: "black" }}>
      <div className="__min_w">
        <div className="puzzle">
          <div className="_head">
            <h2>
              {`${greeting}`} <em>{`${userName}`}</em>
            </h2>
            <div className="btn">
              <button onClick={logoutUser}>Logout </button>
            </div>
          </div>
          <div className="card_wrp">
            <React.Fragment>
              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_Crystal_Salt") && (
                    <div className="_back_color_1"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 2.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_Crystal_Salt") && (
                    <img src={require("../../img/Artboard 2.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_Red_Chili_Pepper") && (
                    <div className="_back_color_2"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 3.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_Red_Chili_Pepper") && (
                    <img src={require("../../img/Artboard 3.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_Tomato_Ketchup") && (
                    <div className="_back_color_3"></div>
                  )}
                </div>

                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 4.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_Tomato_Ketchup") && (
                    <img src={require("../../img/Artboard 4.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_Flamin_Hot") && (
                    <div className="_back_color_4"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 5.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_Flamin_Hot") && (
                    <img src={require("../../img/Artboard 5.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_French_Cheese") && (
                    <div className="_back_color_5"></div>
                  )}
                </div>

                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 8.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_French_Cheese") && (
                    <img src={require("../../img/Artboard 8.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_Salt_&_Vinegar") && (
                    <div className="_back_color_6"></div>
                  )}
                </div>

                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 21.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_Salt_&_Vinegar") && (
                    <img src={require("../../img/Artboard 21.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_SP_Firey_Cheese") && (
                    <div className="_back_color_7"></div>
                  )}
                </div>

                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 20.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_SP_Firey_Cheese") && (
                    <img src={require("../../img/Artboard 20.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_SP_Smoked_Chili") && (
                    <div className="_back_color_8"></div>
                  )}
                </div>

                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 11.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_SP_Smoked_Chili") && (
                    <img src={require("../../img/Artboard 11.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_SP_Salad") && (
                    <div className="_back_color_9"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 10.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_SP_Salad") && (
                    <img src={require("../../img/Artboard 10.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_SP_Spanish_Tomato_Basil") && (
                    <div className="_back_color_10"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 9.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_SP_Spanish_Tomato_Basil") && (
                    <img src={require("../../img/Artboard 9.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_TW_Four_Cheese") && (
                    <div className="_back_color_11"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 22.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_TW_Four_Cheese") && (
                    <img src={require("../../img/Artboard 22.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_TW_Pepper_Jack") && (
                    <div className="_back_color_12"></div>
                  )}
                </div>

                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 23.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_TW_Pepper_Jack") && (
                    <img src={require("../../img/Artboard 23.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  <div className="_back_color_13"></div>
                </div>
                <div className="_img">
                  <div className="text">
                    <h2>
                      YOU COMPLETED <br /> <em>{percentage}%</em>
                    </h2>
                  </div>
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_TW_Ketchup") && (
                    <div className="_back_color_14"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 24.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_TW_Ketchup") && (
                    <img src={require("../../img/Artboard 24.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_TWR_Honey_BBQ") && (
                    <div className="_back_color_15"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 25.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_TWR_Honey_BBQ") && (
                    <img src={require("../../img/Artboard 25.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_TWR_Himalayan_Salt") && (
                    <div className="_back_color_16"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 30.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_TWR_Himalayan_Salt") && (
                    <img src={require("../../img/Artboard 30.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_TWR_Nacho_Cheese") && (
                    <div className="_back_color_17"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 29.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_TWR_Nacho_Cheese") && (
                    <img src={require("../../img/Artboard 29.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_XT_Flamin_Hot") && (
                    <div className="_back_color_18"></div>
                  )}
                </div>

                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 28.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_XT_Flamin_Hot") && (
                    <img src={require("../../img/Artboard 28.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_XT_Creamy_Cheddar") && (
                    <div className="_back_color_19"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 27.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_XT_Creamy_Cheddar") && (
                    <img src={require("../../img/Artboard 27.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_XT_Thai_Sweet_Chilli") && (
                    <div className="_back_color_20"></div>
                  )}
                </div>

                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 26.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_XT_Thai_Sweet_Chilli") && (
                    <img src={require("../../img/Artboard 26.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_ST_Mild_Spicy") && (
                    <div className="_back_color_21"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 31.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_ST_Mild_Spicy") && (
                    <img src={require("../../img/Artboard 31.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_ST_Sea_Salt") && (
                    <div className="_back_color_22"></div>
                  )}
                </div>

                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 32.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_ST_Sea_Salt") && (
                    <img src={require("../../img/Artboard 32.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_ST_Original") && (
                    <div className="_back_color_23"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 33.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_ST_Original") && (
                    <img src={require("../../img/Artboard 33.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_ST_Thai_Campfire") && (
                    <div className="_back_color_24"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 34.png")} alt="" />
                </div>

                <div className="_img">
                  {colors.includes("XL_ST_Thai_Campfire") && (
                    <img src={require("../../img/Artboard 34.png")} alt="" />
                  )}
                </div>
              </div>

              <div className="__card">
                <div className="color_wap">
                  {colors.includes("XL_ST_Tomato") && (
                    <div className="_back_color_25"></div>
                  )}
                </div>
                <div className="_img" style={{ opacity: ".5" }}>
                  <img src={require("../../img/Artboard 35.png")} alt="" />
                </div>
                <div className="_img">
                  {colors.includes("XL_ST_Tomato") && (
                    <img src={require("../../img/Artboard 35.png")} alt="" />
                  )}
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Puzzle;
