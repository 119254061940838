import React from "react";
import "./terms.scss";

const Tearms = () => {
  return (
    <div className="tearm">
      <h1>Terms and Conditions</h1>
      <br />
      <br />
      <div className="terams_ul">
        <ul>
          <li>
            <h2>1. Introduction</h2>
            <p>
              Welcome to notionspromos.com's promotional website. These
              terms and conditions outline the rules and regulations for the use
              of notionspromos.com.
            </p>
            <p>
              By accessing this website, we assume you accept these terms and
              conditions in full. Do not continue to use notionspromos.com
              if you do not accept all of the terms and conditions stated on
              this page.
            </p>
          </li>
          <br />
          <li>
            <h2>2. Eligibility</h2>
            <p>
              Participants must be all age to participate in the promotion.
              Employees of notionsgroup.
            </p>
          </li>
          <br />
          <li>
            <h2>3. How to Enter</h2>
            <p>
              Details about how to enter the promotion, including any entry
              requirements and deadlines, will be provided on the website.
            </p>
          </li>
          <br />
          <li>
            <h2>4. Prize Details</h2>
            <p>
              Details about the prizes, including their value, any restrictions,
              and the method of prize distribution, will be outlined on the
              website.
            </p>
          </li>
          <br />
          <li>
            <h2>5. Winner Selection</h2>
            <p>
              Winners will be selected randomly as outlined on the website. The
              decision is final, and no correspondence will be entered into.
            </p>
          </li>
          <br />
          <li>
            <h2>6. Notification of Winners</h2>
            <p>
              Winners will be notified via email/phone and social media within 7
              days of the draw. If a winner cannot be contacted or does not
              claim the prize within 7 days of notification, we reserve the
              right to withdraw the prize from the winner and pick a replacement
              winner.
            </p>
          </li>
          <br />
          <li>
            <h2>7. Publicity</h2>
            <p>
              By entering the promotion, participants agree to the use of their
              name, image, and entry for promotional purposes. Any personal data
              relating to the winner or any other entrants will be used solely
              in accordance with current data protection legislation and will
              not be disclosed to a third party without the entrant's prior
              consent.
            </p>
          </li>
          <br />
          <li>
            <h2>8. Limitation of Liability</h2>
            <p>
              Notionsgroup is not responsible for any loss or damage suffered by
              the participant as a result of their participation in the
              promotion or use of the prize.
            </p>
          </li>
          <br />
          <li>
            <h2>9. Changes to Terms and Conditions</h2>
            <p>
              Notionsgroup reserves the right to modify these terms and
              conditions at any time. Changes will take effect immediately upon
              their posting on the website.
            </p>
          </li>
          <br />
          <li>
            <h2>10. Governing Law</h2>
            <p>
              These terms and conditions are governed by and construed in
              accordance with the laws of UAE and you irrevocably submit to the
              exclusive jurisdiction of the courts in that location.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Tearms;
