import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./wheel.css";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import Com_Login from "../../components/login/Com_Login";
import Com_Register from "../../components/login/Com_Register";
import back_tex from "../../img/backtext.webp";

const Wheel = () => {
  const { qr, value, promotion_name } = useParams();
  const [isSpinning, setIsSpinning] = useState(false);
  const [currentDegree, setCurrentDegree] = useState(0);
  const [winningItem, setWinningItem] = useState(null);
  const [isStopping, setIsStopping] = useState(false);
  const [spinCount, setSpinCount] = useState(0);
  const intervalRef = useRef(null);
  const decelerationRef = useRef(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [msg, setMsg] = useState();
  const [error, setError] = useState(null);
  const [code_one, setCode_one] = useState();
  const [spinCode, setSpinCode] = useState(value || "");
  const { user } = useContext(AuthContext);
  const userName = user?.details?.name;
  const [showBtn, setShowBtn] = useState(false);

  const handleShowBtn = () => {
    setShowBtn(!showBtn);
  };

  // console.log(winningItem);

  // const hadlleSubmit = async (e) => {
  //   e?.preventDefault();
  //   if (!value) {
  //     try {
  //       const res = await axios.get(
  //         // `https://apipromo.backend-api.live/api/${code_two}`,
  //         `/spin_code/${spinCode}`
  //       );
  //       setApiResponse(res.data.message);
  //       setMsg("Your code is verified");
  //     } catch (err) {
  //       setError("Please enter a valid code", err);
  //     }
  //   }
  // };



    const devApiLink = "http://localhost:4348/api/v1";
    const apiLink = "https://xl.notionspromos.com/api/v1";

  const segments = [
    "Play station ",
    "Try again",
    "Gold Coin",
    "Try again",
    "Smart Watch ",
    "Try again",
  ];
  const segmentDegrees = 360 / segments.length;
  const stoppingAngles = [0, 60, 120, 180, 240, 300]; // Updated to stop at 0 degrees

  const startWheel = () => {
    if (isSpinning) return;
    setIsSpinning(true);
    setWinningItem(null); // Reset winning item
    setIsStopping(false);
    intervalRef.current = setInterval(() => {
      setCurrentDegree((prevDegree) => prevDegree + 10);
    }, 16);
  };

  const stopWheel = () => {
    if (!isSpinning) return;
    clearInterval(intervalRef.current);

    const finalDegree = currentDegree % 360;
    let closestAngle = stoppingAngles.reduce((prev, curr) => {
      return Math.abs(curr - finalDegree) < Math.abs(prev - finalDegree)
        ? curr
        : prev;
    });

    const targetDegree = currentDegree + (closestAngle - finalDegree);
    setIsSpinning(false);

    let speed = 50;

    decelerationRef.current = setInterval(() => {
      setCurrentDegree((prevDegree) => {
        if (Math.abs(prevDegree - targetDegree) <= speed) {
          clearInterval(decelerationRef.current);
          const winningIndex =
            Math.floor((360 - closestAngle) / segmentDegrees) % segments.length;
          setTimeout(() => {
            setWinningItem(segments[winningIndex]);
          }, 15); // Delay to ensure the wheel has completely stopped after 15s
          setIsStopping(true);

          return targetDegree;
        }
        return prevDegree + speed * (prevDegree < targetDegree ? 1 : -1);
      });
      speed *= 0.95; // Deceleration
    }, 16);
  };

  const saveGift = async () => {
    const userEmail = user.details?.email;

    if (!user) {
      return;
    } 
    if (winningItem === "Try again"){
      return;
    }
     try {
       await axios.put(`${apiLink}/winningItem/${winningItem}`, {
         email: userEmail,
         promotion_name: promotion_name,
         user_name: userName,
       });

       setApiResponse("Thank for Participation");
       // console.log("Your code is verified");
     } catch (err) {
       setError("Please enter a valid code", err);
     }
     
  };

  useEffect(() => {
    if (isSpinning || isStopping) {
      document.getElementById(
        "wheel_in"
      ).style.transform = `rotate(${currentDegree}deg)`;
    }
  }, [currentDegree, isSpinning, isStopping]);

  //! save wining item in DB

  useEffect(() => {
    if (!winningItem) {
      return;
    }
    saveGift();
  }, [currentDegree, isSpinning, isStopping, winningItem, user]);

  return (
    <div className="wrap_main">
      <div
        className="wrap_main_in"
        style={{
          backgroundImage: `url(${back_tex})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
        }}
      >
        <div className="heder">
          {!user ? (
            <div className="wheel_form">
              <h2>Spin Wheel</h2>
              <br />

              <div className="wheel_form_hed">
                {!showBtn ? <h3>Login</h3> : <h3>Register</h3>}
              </div>

              {/* <small> If you have account already login</small> */}
              {!showBtn ? <Com_Login /> : <Com_Register />}
              {/* <Com_Login /> */}

              {!showBtn && (
                <small>
                  {" "}
                  If you dont have account{" "}
                  <em onClick={handleShowBtn}>register</em>{" "}
                </small>
              )}
              {showBtn && (
                <small>
                  {" "}
                  If you have account <em onClick={handleShowBtn}>
                    Login
                  </em>{" "}
                </small>
              )}
              <img src={require("../../img/login_jok.png")} alt="" />
            </div>
          ) : (
            <div className="spin_wheel">
              <h2>
                {" "}
                Welcome, {userName}!
                <br />
              </h2>
              <br />
              <img src={require("../../img/Jocker.png")} alt="" />
              <br />

              {apiResponse ? (
                <>
                  <h2>Thank for Participation</h2>
                  <a href="https://xlcrisp.com/"> Explorer more win more</a>
                </>
              ) : (
                <>
                  <small>
                    You can now spin the wheel and win exciting prizes. Good
                    luck!
                  </small>
                </>
              )}
            </div>
          )}
        </div>

        <div className="wrapper_main">
          <div id="wrapper">
            <link
              href="//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css"
              rel="stylesheet"
            />
            <div id="wheel">
              <div id="inner-wheel">
                <div id="wheel_in">
                  {segments.map((segment, index) => (
                    <div
                      key={index}
                      className="sec"
                      style={{
                        transform: `rotate(${index * segmentDegrees}deg)`,
                      }}
                    >
                      <p className="fa">{segment}</p>
                    </div>
                  ))}
                </div>
              </div>
              {!winningItem ? (
                <>
                  {user?.details?.name && qr ? (
                    <div id="spin" onClick={startWheel}>
                      <div id="inner-spin"></div>
                    </div>
                  ) : (
                    <div id="spin">
                      <div id="inner-spin"></div>
                    </div>
                  )}
                  <div id="shine"></div>
                </>
              ) : (
                <>
                  <div id="spin">
                    <div id="inner-spin"></div>
                  </div>
                  <div id="shine"></div>
                </>
              )}
            </div>
            <div id="stop" onClick={stopWheel}>
              <div id="inner-stop">Stop</div>
            </div>
            <div id="txt">
              {winningItem ? (
                <div className="win_plash">
                  {/* <div className="win_text_con">
                    <img src={require("../../img/con.png")} alt="" />
                  </div> */}
                  <div className="win_flash">
                    <img src={require("../../img/win.gif")} alt="" />
                  </div>
                  <div className="win_text">
                    <img src={require("../../img/youwin.png")} alt="" />
                    <div className="win_text_winner">
                      <div className="win_text_winner_in">
                        <p>{winningItem}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p> Spinning the wheel</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wheel;
